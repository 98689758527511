$lime: #e2eb76 !default;
$blue: #2e30e5 !default;
$indigo: #676784 !default;
$purple: #807be4 !default;
$pink: #ff9fd4 !default;
$red: #f81958 !default;
$orange: #ffa87d !default;
$yellow: #faff29 !default;
$green: #a8d29b !default;
$teal: #e2eb76 !default;
$cyan: #3498db !default;
$dark: #1e1010 !default;

$theme-colors: (
  "primary": $blue,
  "secondary": $pink,
  "warning": $orange,
  "info": $purple,
  "danger": $red,
);
