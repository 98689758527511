@import "./theme.scss";
@import "~bootstrap/scss/bootstrap";

.ReactModal__Content {
  margin: 10% 30%;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.react-datepicker__input-container {
  input {
    /** Replicate bootstrap form input component. */
    padding: 0.375rem 0.75rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}

h1,
h2,
h3 {
  font-weight: 300;
}

p,
div,
a {
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}
